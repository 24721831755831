import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import promo from "./promo.mp4";
import "./MediaPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";

class MediaPage extends React.Component {
  render() {
    return (
      <div className="Media">
        <Media query={{ minWidth: 768 }}>
          {matches => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Media-hero"
          style={{
            height: "350px",
            width: "100%",
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top"
          }}
        ></section>
        <section className="Media-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage
                    id="rules.banner"
                    defaultMessage="APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE"
                  />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Media-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="Media-title">Multimedia</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="video my-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/WlENMIdsL7M" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="video my-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Gvg2_ePt-RE"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="video my-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/t4PWB-nZ69g"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/c/pontevedra-4-picos-2025"
                  
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default MediaPage;
