import React from "react";
import "./IndexPage.css";
import Logo from "../_components/Logo";
import LogoBike from "../_components/LogoBike";
import BikeImage from "./bike.jpg";
import RoadImage from "./road.jpg";
import Combi1 from "./combi1.jpg";
import Combi2 from "./combi2.jpg";

class IndexPage extends React.Component {
  render() {
    return (
      <div className="IndexPage">
        <div className="column">
          <div className="background" style={{backgroundImage: `url(${BikeImage})`}}>
            <div className="foreground-content">
              <LogoBike/>
              <div className="button-group">
                <a href="https://www.pontevedra4picos.com/inicio" className="button bike">
                  Web
                </a>
                <a href="https://sportmaniacs.com/c/pontevedra-4-picos-2025" className="button bike">
                  Inscríbete
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <div className="column">
          <div className="background" style={{backgroundImage: `url(${RoadImage})`}}>
            <div className="foreground-content">
              <Logo/>
              <div className="button-group">
                <a href="/inicio" className="button road">
                  Web
                </a>
                <a href="https://sportmaniacs.com/c/pontevedra-4-picos-2025" className="button road">
                  Inscríbete
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <section className="right column">
          <div className="background">
            <div className="sub-column" style={{backgroundImage: `url(${Combi1})`}}></div>
            <div className="sub-column" style={{backgroundImage: `url(${Combi2})`}}></div>
            <div className="foreground-content combi">
              <h2 className="title">COMBINADA</h2>
              <h4 className="subtitle">Bike + Road</h4>
              <div className="inform-box">
                Infórmate en cada una de las webs
              </div>
              <a href="https://sportmaniacs.com/c/pontevedra-4-picos-2025" className="button combi">Inscríbete</a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default IndexPage;
