import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import Image2 from './image2.jpg';
import picos4 from './4picos.jpeg';
import picos3 from './3picos.jpeg';
import perfil from './perfil.jpg';
import './RecorridoPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';

class RecorridoPage extends React.Component {
  render() {
    return (
      <div className="Distances">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Distances-hero" 
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'
          }}>
        </section>
        <section className="Distances-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage id="Distances.banner" defaultMessage="APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE" />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Distances-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                  <h3 className="Distances-title">
                    <FormattedMessage id="Distances.title" defaultMessage="Recorrido" />
                  </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <article className="Distances-info-article">
                  <h4 className="Distances-info-title">
                    <FormattedMessage id="Distances.info.title" defaultMessage="En esta ocasión, la prueba ofrece una distancia única de 112 km con 1850 m de desnivel acumulado positivo. " />
                  </h4>
                  <p className="Distances-info-text text-justify">
                    <ul>
                      <li><strong>Distancia:</strong> 112 km</li>
                      <li><strong>Desnivel Positivo acumulado:</strong> 1850 metros.</li>
                      <li><strong>2 TRAMOS CRONOMETRADOS</strong></li>
                    </ul>
                  </p>
                  <img src={perfil} alt="" className="img-fluid"/>
                  <div className="Rules-button-wrapper my-5">
                    <a className="Rules-pdf-button" href="#"  rel="noopener noreferrer">Track de la prueba</a>
                  </div>
                </article>

              </div>
              <div className="col-md-12 col-lg-6">
                <div className="Distances-info-img"
                    style={{
                      backgroundImage: `url(${Image2})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top center',
                      backgroundSize: 'cover',
                      height: '800px',
                      marginRight: '-15px',
                      marginLeft: '-15px'
                    }}>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed" href="https://sportmaniacs.com/c/pontevedra-4-picos-2025"  rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default RecorridoPage;
