import React from "react";
import "./HomePage.css";
import { FormattedMessage } from "react-intl";
import Header from "../_components/Header";
import Button from "../_components/Button";
import HeaderMobile from "../_components/HeaderMobile";
import Footer from "../_components/Footer";
import Slider from "../_components/Slider";
import { Link } from "react-router-dom";
import Arrow from "./rightArrow.svg";
import Image1 from "./image1.jpg";
import Image3 from "./image3.jpg";
import Sponsors from "../_components/Sponsors";
import Media from "react-media";
import Countdown from "../_components/Countdown";

class HomePage extends React.Component {
  render() {
    return (
      <>
        <div className="Home">
          <Media query={{ minWidth: 768 }}>
            {(matches) => (matches ? <Header /> : <HeaderMobile />)}
          </Media>
          <Slider />
          <section className="Home-countdown">
            <Media query={{ minWidth: 768 }}>
              {(matches) =>
                matches ? "" : <Countdown date="2025-05-11T00:00:00" />
              }
            </Media>
          </section>
          <section className="Home-alert">
            <h2 className="slider-caption2">
              APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE
            </h2>
          </section>
          <section className="Home-pager">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <a
                    className="Home-pager-link"
                    href="#"
                    
                    rel="noopener noreferrer"
                  >
                    <h2 className="Home-pager-title">
                      <FormattedMessage
                        id="home.enrolled"
                        defaultMessage="Inscritos"
                      />
                    </h2>
                    <h5 className="Home-pager-subtitle">
                      <FormattedMessage
                        id="home.enrolled.list"
                        defaultMessage="Comprueba tu inscripción"
                      />
                    </h5>
                    <span className="Home-pager-arrow-wrapper">
                      <img
                        className="Home-pager-arrow"
                        src={Arrow}
                        alt="arrow"
                      />
                    </span>
                  </a>
                </div>
                <div className="col-md-12 col-lg-6">
                  <a
                    className="Home-pager-link"
                    href="#"
                    
                    rel="noopener noreferrer"
                  >
                    <h2 className="Home-pager-title">
                      <FormattedMessage
                        id="home.results"
                        defaultMessage="Resultados de la prueba"
                      />
                    </h2>
                    <h5 className="Home-pager-subtitle">
                      <FormattedMessage
                        id="home.results.checkPosition"
                        defaultMessage="Comprueba tu posición de esta edición"
                      />
                    </h5>
                    <span className="Home-pager-arrow-wrapper">
                      <img
                        className="Home-pager-arrow"
                        src={Arrow}
                        alt="arrow"
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <Sponsors />
          <section className="Home-info">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 d-flex order-2 order-lg-1">
                  <article className="Home-info-text">
                    <h4 className="Home-info-title">RECORRIDO INCREIBLE</h4>
                    <p>
                      <FormattedMessage
                        id="home.description"
                        defaultMessage='La empresa Global DXT en colaboración con el Concello de Pontevedra organizan el 11 de Mayo la sexta edición de LA ROAD PONTEVEDRA – CLÁSICA EVARISTO PORTELA'
                      />
                    </p>
                  </article>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 order-1 order-lg-2">
                  <div
                    className="Home-info-img"
                    style={{
                      backgroundImage: `url(${Image1})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </section>

          <section className="Home-banner">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div
                    className="Home-banner-img"
                    style={{
                      backgroundImage: `url(${Image3})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <article className="Home-banner-text">
                    <h6>
                      11 de Mayo de 2025
                    </h6>
                    <h4 className="Home-banner-title">
                      <FormattedMessage
                        id="home.captionBanner1"
                        defaultMessage="ELIGE TU DISTANCIA"
                      />
                    </h4>
                    <p>
                      <FormattedMessage
                        id="home.captionBanner1.text2"
                        defaultMessage="Distancia única:La Road Pontevedra tendrá un recorrido único con  una distancia de 112 km con un acumulado Positivo de 1850 metros."
                      />
                    </p>
                    <div className="Home-banner-button-wrapper">
                      <a
                        className="Home-banner-button"
                        href="https://sportmaniacs.com/c/pontevedra-4-picos-2025"
                        
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="home.enroll"
                          defaultMessage="Inscríbete"
                        />
                      </a>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <Media query={{ minWidth: 768 }}>
            {(matches) =>
              matches ? (
                ""
              ) : (
                <section className="inscription-fixed-bar">
                  <Button
                    className="inscription-fixed"
                    href="https://sportmaniacs.com/c/pontevedra-4-picos-2025"
                    
                    rel="noopener noreferrer"
                  >
                    Inscríbete
                  </Button>
                </section>
              )
            }
          </Media>
        </div>
        
      </>
    );
  }
}

export default HomePage;
