import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Close from './x.svg';
import LanguageSwitcher from '../LanguageSwitcher';
import Dropdown from 'react-multilevel-dropdown';


class Menu extends Component {
  render() {
    var visibility = 'hide';

    if (this.props.menuVisibility) {
      visibility = 'show';
    }

    return (
      <div id='flyoutMenu' className={visibility}>
        <div className='Header-top'>{/* <LanguageSwitcher /> */}</div>
        <button className='btn' onMouseDown={this.props.handleMouseDown}>
          <img src={Close} alt='icon'/>
        </button>
        <ul>
          <h3>
            <a
              href='#collapseLaPrueba'
              role='button'
              className='Header-link'
              data-toggle='collapse'
              aria-expanded='false'
              aria-controls='collapseLaPrueba'>
              La Prueba
            </a>
          </h3>
          <div className='collapse menu-mobile-collapse' id='collapseLaPrueba'>
            <p>
              <Link className='Header-link' to='/descripcion'>
                Descripción
              </Link>
            </p>
            <p>
              <Link className='Header-link' to='/modalidades'>
                Modalidades
              </Link>
            </p>
            <p>
              <Link className='Header-link' to='/tarifas'>
                <FormattedMessage id='header.rates' defaultMessage='Tarifas'/>
              </Link>
            </p>
            <p>
              <Link className='Header-link' to='/reglamento'>
                Reglamento
              </Link>
            </p>
            <p>
              <Link className='Header-link' to='/recorrido'>
                El recorrido
              </Link>
            </p>
            <p>
              <Link className='Header-link' to='/seguro-devolucion'>
                Seguro de devolución
              </Link>
            </p>
            <p>
              <Link className='Header-link' to='/preguntas-frecuentes'>
                Preguntas frecuentes
              </Link>
            </p>
          </div>
          <div
            className='collapse menu-mobile-collapse'
            id='multimedia'>
            <p>
              <Link className='Header-link' to='/fotos'>
                Fotos
              </Link>
            </p>
            <p>
              <Link className='Header-link' to='/videos'>
                Vídeos
              </Link>
            </p>
          </div>
          <h3>
            <a
              href='#collapseGlobalDXTRaces'
              role='button'
              className='Header-link'
              data-toggle='collapse'
              aria-expanded='false'
              aria-controls='collapseGlobalDXTRaces'>
              <FormattedMessage
                id='header.races.globaldxt'
                defaultMessage='Pruebas GlobalDXT'
              />
            </a>
          </h3>
          <div
            className='collapse menu-mobile-collapse'
            id='collapseGlobalDXTRaces'>
            <a
              className='dropdown-item'
              href='http://www.pontevedra4picos.com/'
              target='_blank'
              rel='noopener noreferrer'>
              Pontevedra 4 Picos
            </a>
            <a
              className='dropdown-item'
              href='http://www.epicracepontevedra.com/'
              target='_blank'
              rel='noopener noreferrer'>
              Epic Race Pontevedra
            </a>
            <a
              className='dropdown-item'
              href='http://www.costaatlanticamtbtour.com/'
              target='_blank'
              rel='noopener noreferrer'>
              Costa Atlántica MTB Tour
            </a>
          </div>
          <h3>
            <a
              href='#collapseFriendRaces'
              role='button'
              className='Header-link'
              data-toggle='collapse'
              aria-expanded='false'
              aria-controls='collapseFriendRaces'>
              <FormattedMessage
                id='header.races.friends'
                defaultMessage='Pruebas Amigas'
              />
            </a>
          </h3>
          <div
            className='collapse menu-mobile-collapse'
            id='collapseFriendRaces'>
            <a
              className='dropdown-item'
              href='http://www.algarvebikechallenge.com/es/'
              target='_blank'
              rel='noopener noreferrer'>
              Algarve Bike Challenge
            </a>
            <a
              className='dropdown-item'
              href='https://mountainquest.pt/'
              target='_blank'
              rel='noopener noreferrer'>
              Mountain Quest
            </a>
            <a
              className='dropdown-item'
              href='https://dourobikerace.com/es/dbr/'
              target='_blank'
              rel='noopener noreferrer'>
              Douro Bike Race
            </a>
            <a
              className='dropdown-item'
              href='https://lalinbikerace.es/'
              target='_blank'
              rel='noopener noreferrer'>
              Lalín Bike Race
            </a>
            <a
              className='dropdown-item'
              href='https://www.amigosdamontanha.com/_maratona_btt_5_cumes_170'
              target='_blank'
              rel='noopener noreferrer'>
              Maratona BTT 5 Cumes
            </a>
            <a
              className='dropdown-item'
              href='https://www.gigantedepiedra.com/'
              target='_blank'
              rel='noopener noreferrer'>
              Gigante de Piedra
            </a>
          </div>

          {/* <h3>
            <a
              href='#wearDropdown'
              role='button'
              className='Header-link'
              data-toggle='collapse'
              aria-expanded='false'
              aria-controls='wearDropdown'>
              Ropa
            </a>
          </h3>
          <div
            className='collapse menu-mobile-collapse'
            id='wearDropdown'>
            <Link className='dropdown-item' to='/ropa'>
              Ropa evento
            </Link>
            <a
              className='dropdown-item'
              href="https://eventos.emesports.es/inscripcion/tienda-global-copia/inscripcion_datos/"
              target='_blank'
              rel='noopener noreferrer'>
              Tienda Global DXT
            </a>
          </div> */}
          <h3>
            <Link className='Header-link' to='/sponsors'>
              Sponsors
            </Link>
          </h3>
          <h3>
            <Link className='Header-link' to='/alojamiento'>
              Alojamiento
            </Link>
          </h3>
        </ul>
        <div className='Menu-mobile-button-wrapper'>
          <a
            className='Menu-mobile-button'
            href='https://sportmaniacs.com/es/check-inscription/pontevedra-4-picos-2025?event=676154d3-e860-49e0-b3c7-4627ac1f2fb7'
            target='_blank'
            rel='noopener noreferrer'>
            Comprobar inscripción
          </a>
        </div>
        <div className='Menu-mobile-button-wrapper'>
          <a
            className='Menu-mobile-button'
            href='#'>
            <FormattedMessage id='home.enroll' defaultMessage='Inscríbete'/>
          </a>
        </div>
      </div>
    );
  }
}

export default Menu;
