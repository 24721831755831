import React from 'react';
import { Route } from 'react-router-dom';
import IndexPage from '../IndexPage';
import HomePage from '../HomePage';
import GalleryPage from '../GalleryPage';
import RatesPage from '../RatesPage';
import DescriptionPage from '../DescriptionPage';
import RulesPage from '../RulesPage';
import ContactPage from '../ContactPage';
import MediaPage from '../MediaPage';
import EmbassadorPage from '../EmbassadorPage';
import WearPage from '../WearPage';
import RecorridoPage from '../RecorridoPage';
import SponsorsPage from '../SponsorsPage';
import HostingPage from '../HostingPage';
import SeguroPage from '../SeguroPage';
import PreguntasPage from '../PreguntasPage';
import ModalidadesPage from '../ModalidadesPage';
import PhotosPage from '../PhotosPage';
import VideosPage from '../VideosPage';

class App extends React.Component {
  render() {
    return (
      <div>
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/inicio" component={HomePage} />
        <Route path="/descripcion" component={DescriptionPage} />
        <Route path="/tarifas" component={RatesPage} />
        <Route path="/reglamento" component={RulesPage} />
        <Route path="/multimedia" component={MediaPage} />
        <Route path="/galeria" component={GalleryPage} />
        <Route path="/contacto" component={ContactPage} />
        <Route path="/embajadores" component={EmbassadorPage} />
        <Route path="/ropa" component={WearPage} />
        <Route path="/recorrido" component={RecorridoPage} />
        <Route path="/sponsors" component={SponsorsPage} />
        <Route path="/alojamiento" component={HostingPage} />
        <Route path="/seguro-devolucion" component={SeguroPage} />
        <Route path="/preguntas-frecuentes" component={PreguntasPage} />
        <Route path="/modalidades" component={ModalidadesPage} />
        <Route path="/fotos" component={PhotosPage} />
        <Route path="/videos" component={VideosPage} />
      </div>
    );
  }
}

export default App;
